<template>
  <div class="benchTask" :style="{ height: `calc(100% - ${heightGap}px)` }">
    <div class="taskPool" v-for="(item, id) in progressData" :key="id">
      <div class="taskTitle">
        <span class="skillText">{{ item.laneStatus }}</span>
      </div>
      <div class="cardsList">
        <div
          class="cardContent"
          v-for="(carditem, index) in item.tasks"
          :key="index"
        >
          <div
            :class="['setCardBorder', setCardBorder(carditem, item.laneStatus)]"
          >
            <a-card
              @click="editTask(carditem, item.laneStatus)"
              :bordered="false"
            >
              <div class="titleH">
                <div class="title" :title="carditem.taskName">
                  {{ carditem.taskName }}
                </div>
                <div class="titleTag">
                  <a-tag
                    v-show="
                      item.laneStatus == 'Doing' &&
                      carditem.taskStatus == '3' &&
                      carditem.taskStatus !== '4'
                    "
                    v-auth="['system:benchTask:lm']"
                    >待处理
                  </a-tag>
                  <a-tag
                    color="red"
                    v-show="
                      item.laneStatus == 'Doing' && carditem.taskStatus == '4'
                    "
                    >未通过
                  </a-tag>
                  <a-tag
                    color="orange"
                    v-show="
                      item.laneStatus == 'Doing' && carditem.isAnswered == '0'
                    "
                    >未回答
                  </a-tag>
                </div>
              </div>
              <div class="cInfo">
                <a-avatar
                  style="margin-right: 5px"
                  v-if="carditem.photoUrl"
                  :src="carditem.photoUrl"
                >
                </a-avatar>
                <a-avatar style="margin-right: 5px" v-else>
                  <template #icon>
                    <UserOutlined />
                  </template>
                </a-avatar>
                <span>{{ carditem.userName }}</span>
              </div>
              <div class="bottomCon">
                <div class="timeIcon">
                  <DashboardOutlined
                    style="font-size: 13px; margin-right: 5px"
                  />
                  {{ carditem.beginTime }}~{{ carditem.endTime }}
                </div>
                <div class="btn">
                  <a-button
                    type="primary"
                    ghost
                    class="newTask"
                    v-show="item.laneStatus == 'Task'"
                    v-auth="['system:benchTask:consultant']"
                    @click.stop="receiveTask(carditem, id, index)"
                    >接受
                  </a-button>
                  <a-button
                    type="primary"
                    class="newTask"
                    v-show="
                      item.laneStatus == 'Doing' &&
                      carditem.taskStatus !== '3' &&
                      carditem.isAnswered == '1'
                    "
                    v-auth="['system:benchTask:consultantCommit']"
                    @click.stop="commitTask(carditem, id, index)"
                    >提交
                  </a-button>
                  <a-button
                    disabled
                    class="newTask"
                    v-show="
                      item.laneStatus == 'Doing' && carditem.taskStatus == '3'
                    "
                    style="background: #1c77cb; color: #fff"
                    v-auth="['system:benchTask:consultantCommitAll']"
                    >已提交
                  </a-button>
                </div>
              </div>
            </a-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <consultant-task-modal
    ref="consultantTaskRef"
    :taskStatus="taskStatus"
    @refresh-data="refreshData"
  />
</template>

<script>
import { defineComponent, ref,onMounted,watch } from "vue";
import { DashboardOutlined, UserOutlined } from "@ant-design/icons-vue";
import "@/assets/benchTask/link/iconfont.css";
import { message } from "ant-design-vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import ConsultantTaskModal from "@/views/home/TaskManagement/BenchTask/component/consultantTaskModal";
import { useRoute} from "vue-router";

export default defineComponent({
  components: {
    ConsultantTaskModal,
    DashboardOutlined,
    UserOutlined,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const progressData = ref([]);
    const nowX = ref(0);
    const nowY = ref(0);
    const heightGap = ref(0);
    const route = useRoute();

    const consultantTaskRef = ref();
    const taskStatus = ref("");
    const consultantEditTask = (record) => {
      consultantTaskRef.value.editTask(record.id);
    };

    const editTask = (record, status) => {
      taskStatus.value = status;

      consultantEditTask(record);
    };

    const refreshData = () => {
      init();
    };

    //接受
    const receiveTask = (item) => {
      $api.receiveBenchTaskList({ taskId: item.id }).then((res) => {
        if (res.code == 200) {
          init();
        }
      });
    };
    //提交
    const commitTask = (item) => {
      $api.commitBenchTaskList({ taskId: item.id }).then((res) => {
        if (res.code == 200) {
          message.success("提交成功");
          init();
        }
      });
    };
    //通过

    //获取卡片边框颜色
    const setCardBorder = (card, title) => {
      if (title !== "Doing") return;
      if (card.overdue == "overdue") {
        return "setCardBorderRed";
      } else if (card.overdue == "approaching") {
        return "setCardBorderYellow";
      }
      return "setCardBorder";
    };

    //获取卡片信息
    const init = () => {
      const apiName=route.query.id?'consultantTasksById':'getconsultantTasks'
      $api[apiName](route?.query?.id).then((res) => {
        progressData.value = res.data;
      });
    };
    onMounted(()=>{
      // console.log('consultantbenchTaskDetail,onMounted>>>', route);
    })
    watch(
      () => route.query.id,
      () => {
        init();
      }
    )
    init();
    return {
      nowX,
      nowY,
      consultantTaskRef,
      taskStatus,
      consultantEditTask,
      editTask,
      refreshData,
      progressData,
      receiveTask,
      commitTask,
      heightGap,
      setCardBorder,
      // handleChooseOutSide
    };
  },
});
</script>

<style lang="less" scoped>
.chooseOne {
  position: fixed;
  top: 120px;
  right: 50px;
  background: #fff;
  min-width: 250px;
  padding: 0px 10px 5px;
  box-shadow: 0px 0px 1.04167vw 0.15625vw #e9ebed;
  .chooseTitle {
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  :deep(.ant-divider-horizontal) {
    margin: 0px;
  }
  .mb5 {
    margin-bottom: 5px;
    font-weight: 600;
  }
}
.backBenchTask {
  line-height: 40px;
  margin: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .nameShow {
    margin-right: 10px;
  }
  .cancelChooseBtn {
    margin-right: 20px;
    border-color: #fff;
    &:focus {
      background-color: rgb(219 219 219 / 80%) !important;
    }
  }
  .chooseBtn {
    border-color: #fff;
  }

  .back {
    color: #000;
    margin-left: 3px;
    font-size: 15px;
    cursor: pointer;
  }
}

.benchTask {
  display: flex;
  width: 100%;
  padding: 10px 100px 10px;
  justify-content: space-between;

  > div {
    width: 31%;
    background: rgba(3, 14, 44, 0.04) !important;

    &:hover {
      box-shadow: 0 2px 8px #00000017;
    }
  }

  .skillText {
    font-size: 26px;
    font-weight: 500;
  }

  #create-newCard {
    background: white;
    text-align: center;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 5px;
    cursor: pointer;
  }
}

.taskPool {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  height: calc(100%);
  flex-direction: column;
}

.cardsList {
  width: calc(100%);
  overflow: auto;
  // flex:1;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }

  .cardContent {
    width: calc(100%);
    padding: 4px;
    .setCardBorder {
      border: 2px solid #d9d9d9;
      border-radius: 8px;
    }
    .setCardBorderRed {
      border: 2px solid #ff0000;
      border-radius: 8px;
    }
    .setCardBorderYellow {
      border: 2px solid #ffce00;
      border-radius: 8px;
    }
  }

  .icon {
    line-height: 26px;
  }

  .ant-card {
    cursor: pointer;
  }
}

.titleH {
  display: flex;
  flex-direction: row;
  height: 25px;
  line-height: 25px;
  .title {
    font-size: 18px;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    .timeData {
      font-weight: 200;
    }
  }
}

.time {
  color: #8d8d8d;
  font-size: 2px;
  float: right;
}

:deep(.ant-card-head-title) {
  padding: 3px 0px;
}

.sumbitBtn {
  border-radius: 4px;
}

:deep(.ant-card-body) {
  padding: 16px;
}

:deep(.ant-card) {
  border-radius: 8px;
}

.newTask {
  float: right;
  height: 32px;
  border-radius: 4px;
}

.taskTitle {
  line-height: 23px;
  margin-bottom: 16px;
}

.rightBottomBtn {
  z-index: 1000;
  position: fixed;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  left: 0;
  top: 0;
  background: #fff;
  padding: 5px 30px 5px 5px;
  display: flex;
  flex-direction: column;

  .mb5 {
    margin-bottom: 5px;
    color: #000;
  }
}

.iconLink {
  color: #8d8d8d;
  font-size: 18px;
  margin-left: 10px;
}

// :deep(.ant-card-bordered) {
//   border: 1px solid #d9d9d9;
// }

:deep(.ant-popover-inner-content) {
  padding: 3px 41px 3px 0px;
}

.popoverCard {
  left: 390px;
}

:deep(.ant-popover-arrow) {
  display: none;
}

.bottomCon {
  .btn {
    height: 26px;
    margin-top: -25px;
  }
}

:deep(.ant-btn) {
  &:hover {
    border-color: transparent;
  }
}

.cInfo {
  margin: 15px 0px 15px 0px;
}
</style>
